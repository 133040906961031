<template>
  <div class="ExamineApplication">
    <div class="table">
      <div class="tableForm">
        <el-table
          :data="examinList"
          style="width: 100%"
          :default-sort="{prop: 'createDate', order: 'descending'}"
          @row-click="rowClick"
        >
          <el-table-column
            prop="applyTypeName"
            label="类型"
          >
          </el-table-column>
          <el-table-column
            prop="relName"
            label="关联"
          >
          </el-table-column>
          <el-table-column
            prop="createDate"
            label="申请时间"
            sortable
          >
          </el-table-column>
          <el-table-column
            prop="createUser.accountNo"
            label="申请人"
          >
          </el-table-column>
          <el-table-column
            prop="changeDate"
            label="审批时间"
          >
          </el-table-column>
          <el-table-column
            prop=""
            label=""
          >
          </el-table-column>
          <el-table-column label="状态">
            <template #default="scope">
              <span :class="judgeStatus(scope.row.status)">{{scope.row.statusName}}</span>
            </template>
          </el-table-column>
          <el-table-column
            width='100'
            align='center'
          >
            <template #default="scope">
              <el-dropdown trigger="click">
                <div
                  class="iconfont icongengduo"
                  @click.stop="()=>{}"
                ></div>
                <template #dropdown>
                  <el-dropdown-menu>
                    <el-dropdown-item @click.stop='deleteExamine(scope.row)'>删除</el-dropdown-item>
                    <el-dropdown-item @click.stop='examineDetail(scope.row)'>查看详情</el-dropdown-item>
                    <!-- <el-dropdown-item command='3'>同意</el-dropdown-item>
                  <el-dropdown-item command='4'>拒绝</el-dropdown-item> -->
                  </el-dropdown-menu>
                </template>
              </el-dropdown>
            </template>
          </el-table-column>
        </el-table>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent, reactive, watch } from "vue";
import { deleteExamine } from "@/api/examine";
export default defineComponent({
  name: "ExamineOfMine",
  components: {},
  props: {
    examinList: {
      type: Array,
      default: null,
    },
  },
  mounted() {
    console.log("examineApplication");
    this.$emit("btnDisplay", false);
    this.$emit("judgeContent", "record");
  },
  methods: {
    //状态列样式
    judgeStatus(item) {
      return item == 2 ? "pass" : item == 1 ? "" : "refuse";
    },
    //删除我的申请
    async deleteExamine(row) {
      console.log(row, "delete");
      let res = await deleteExamine({ applyId: row.applyId });
      if (res.code == 200) {
        this.$message({
          type: "success",
          message: "删除成功",
        });
        this.$emit("deletExamine");
      }
    },
    //查看申请详情
    examineDetail(row) {
      console.log(row, "detail");
      this.$router.push({
        path: "/examine/details",
        query: { applyId: row.applyId, content: "record" },
      });
    },
    rowClick(row, column, event) {
      this.examineDetail(row);
    },
    more() {},
  },
});
</script>

<style lang="scss" scoped>
@import "@/assets/style/comman.scss";
.ExamineApplication {
  // padding: 20px 0;
  padding-bottom: 20px;
  .table {
    padding: 0 30px;
    .tableForm {
      // border-top: 1px solid #eee;
      .el-dropdown {
        &:hover {
          cursor: pointer;
        }
      }
      .fileNameList {
        display: flex;
        align-items: center;
        .fileIcon {
          width: 20px;
          height: 20px;
        }
        .fileNameTitle {
          font-size: 14px;
          color: #555;
          margin-left: 10px;
          &:hover {
            cursor: pointer;
            color: $main-color;
          }
        }
      }
    }
  }
  .pass {
    color: chartreuse;
  }
  .refuse {
    color: red;
  }
}
</style>